/* eslint-disable no-unused-vars */
import * as React from 'react';
import { CompanyProfile } from '@api/models/companyApi.models';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { KeycloakAppRole } from '@api/models/userApi.models';
import { CompanySettingsContextProvider } from '@common/context/companySettingsContext';
import ESourceTabContent from './ESourceTabContent';
import InvitesTabContent from './InvitesTabContent'; 
import SeatApprovalsTabContent from './SeatApprovalsTabContent';
import SeatManagementTabContent from './SeatManagementTabContent';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { Tab } from '@mui/material';
import { useFetchSettings } from '@common/fetches/useFetchSettings';
import { useFetchESourceGroups } from '@common/fetches/useFetchESourceGroups';
import { CompanyApi } from '@api/Company.api';
import { EmployerRole } from '@api/models/employerApi.models';
import { Seat } from '@api/models/settingsApi.models';

const Styled = {
  TabList: styled(TabList)({
    '&.MuiTabs-indicator': {
      backgroundColor: 'red'
    }
  }),
  Tab: styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
      ...(theme.typography as any).EC_TYPE_2XL,
      marginBottom: '8px',
      color: theme.palette.GRAY_3.main
    }
  })),
  TabPanel: styled(TabPanel)({
    padding: 0
  })
};

interface SeatManagementProps  {
  company: CompanyProfile,
  openToast: (msg: string) => void;
}

const SeatManagement: React.FC<SeatManagementProps> = ({ 
  openToast,
  company,
}) => {
  const [companySeats, setCompanySeats] = React.useState<Seat[]>([]);
  const [glrcSeats, setGlrcSeats] = React.useState<number>(0);
  const glrcSeatsRemaining = React.useRef<number>(0);
  const { esourceGroups, setESourceGroups } = useFetchESourceGroups(company.id);

  const {
    setLoading,
    fetchSentInvites,
    sentInvites,
    fetchJoinRequests,
    joinRequests,
  } = useFetchSettings();

  const fetchSeatsData = React.useCallback(async (): Promise<void> => {
    try {
      // Run both fetch operations in parallel
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, employersResponse] = await Promise.allSettled([
        (async () => {
          await fetchSentInvites(company.id);
          setGlrcSeats(company.glrcSeats ?? 0);
        })(),
        CompanyApi.getEmployersByCompanyId(company.id),
      ]);
      
      if (employersResponse.status === 'fulfilled') {
        const employers = employersResponse.value.data;
        const seats = employers.map(employer => { return {
          employerId: employer.id,
          name: `${employer.user?.firstName ?? ''} ${employer.user?.lastName ?? ''}`,
          email: employer.user?.email ?? '',
          firstName: employer.user?.firstName ?? '',
          lastName: employer.user?.lastName ?? '',
          keycloakUserId: employer.user?.id ?? '',
          keycloakUserRoles: employer.user?.userToRoles?.map((link) => link.role) ?? [],
          status: employer.status,
          employerRoles: employer.companyToEmployers?.filter(link => link.company.id === company.id).map((link) => link.employerRole) ?? [],
          e_source_group: employer.e_source_group,
          companyToEmployers: employer.companyToEmployers,
          isSelf: false,
        } as Seat;});
        const filteredSeats: Seat[] = seats?.filter(employer => !!employer) as Seat[] ?? [];
        setCompanySeats(filteredSeats);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [company]);

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        await fetchSeatsData();
      } catch (error) {
        console.error('Error in useEffect fetchData:', error);
      }
    };
    fetchData();
  }, [company]);

  React.useEffect(() => {
    // No async operations here, so no need for isMounted check
    const activeGlrcUsers = companySeats.filter(
      (seat) => seat.keycloakUserRoles.filter((role) => role.toString() === KeycloakAppRole.ESOURCE_USER).length || 0
    );

    const eSourceInvitesSent = sentInvites.filter( invite =>
      invite.companyToUserInvites?.some(link => link.employerRole === EmployerRole.ESOURCE_USER)
      && (Date.parse(invite.expiresAt) >= Date.now()) 
    );

    const totalGlrcUsers = eSourceInvitesSent.length + activeGlrcUsers.length;
    glrcSeatsRemaining.current = glrcSeats - totalGlrcUsers;
  }, [companySeats, glrcSeats, sentInvites]);

  const initialTabId = '1';
  const [tabValue, setTabValue] = React.useState(initialTabId);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setTabValue(newValue);
  };

  const handleSeatRequestUpdate = (): void => {
    fetchJoinRequests(company.id);
  };

  return (
    <>
      <StepSection>
        <TabContext value={tabValue}>
          <Styled.TabList onChange={handleTabChange}>
            <Styled.Tab label="Seats" value="1" />
            <Styled.Tab label="ESource" value="2" />
            <Styled.Tab
              label={`Approvals (${joinRequests.length})`}
              value="3"
            />
            <Styled.Tab label={`Invites (${sentInvites.length})`} value="4" /> 
          </Styled.TabList>
          <CompanySettingsContextProvider
            value={{
              setLoading,
              companyId: company.id,
              glrcSeats,
              glrcPermissions: company.glrcPermissions,
              sentInvites,
              joinRequests,
              fetchSentInvites,
              handleSeatRequestUpdate,
              esourceGroups,
              setESourceGroups,
              openToast,
              glrcSeatsRemaining: glrcSeatsRemaining.current,
            }}
          >
            <Styled.TabPanel value="1">
              <SeatManagementTabContent 
                company={company}
                companySeats={companySeats}
                setCompanySeats={setCompanySeats}
              />
            </Styled.TabPanel>
            <Styled.TabPanel value="2">
              <ESourceTabContent 
                companySeats={companySeats}
              />
            </Styled.TabPanel>
            <Styled.TabPanel value="3">
              <SeatApprovalsTabContent />
            </Styled.TabPanel>
            <Styled.TabPanel value="4">
              <InvitesTabContent
                companyId={company.id}
                refreshSeatsData={fetchSeatsData}
              />
            </Styled.TabPanel>
          </CompanySettingsContextProvider>
        </TabContext>
      </StepSection>
    </>
  );
};

export default SeatManagement;
