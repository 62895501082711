import * as React from 'react';
import {
  Box,
  Button,
  Icon,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  CampusCourseStatus,
  CourseStatusesIneligibleForSiteProposal,
  CourseWithExternships
} from '@api/models/enrollmentApi.models';
import {
  ExternshipEntity,
  ExternshipStatus
} from '@api/models/externshipApi.models';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AttestationSection from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection//StudentCourseCard/AttestationSection';
import { AttestationType } from '@interfaces/attestation.interface';
import CourseExternshipAction from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/StudentCourseCard/CourseExternshipAction';
import CourseExternshipDetails from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/StudentCourseCard/CourseExternshipDetails';
import EcProgressBar from '@components/EcProgressBar';
import ExternshipAgreement from '@components/ExternshipAgreements/ExternshipAgreement';
import ExternshipDetail from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/ExternshipCard/ExternshipDetail';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getExternshipLocation } from '@common/helpers/externshipHelpers/getExternshipLocation';
import { getExternshipStatusLabel } from '@common/helpers/externshipHelpers/getExternshipStatusLabel';
import { getTimesheetStatusHours } from '@common/helpers/externshipHelpers/getTimesheetStatusHours';
import { styled } from '@mui/system';
import { useCandidateDashboardContext } from '@common/context/candidateDashboardContext';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    padding: '16px',
    border: `1px solid ${theme.palette.GRAY_F4.main}`,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 'unset',
      width: 'calc(100% + 64px)',
      transform: 'translateX(-32px)'
    }
  })),
  FlexRow: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '12px',
    '& i': {
      fontSize: '16px'
    }
  }),
  SiteSectionWrapper: styled(Box)(({ theme }) => ({
    '& > *:not(:last-child)': {
      paddingBottom: '16px',
      marginBottom: '16px',
      borderBottom: `1px solid ${theme.palette.OUTLINE.main}`
    }
  })),
  SiteRoot: styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {}
  })),
  CompanyBox: styled(Box)(({ theme }) => ({
    flex: '0 1 35%',
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      width: '100%',
      marginBottom: '16px'
    }
  })),
  ApproverBox: styled(Box)(({ theme }) => ({
    flex: '1 0 65%',
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      width: '100%'
    }
  })),
  CardBtn: styled(Button)(({ theme }) => ({
    maxWidth: '420px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  }))
};

interface SiteSectionProps extends ExternshipEntity {
  courseScheduleId: number;
}

const SiteSection = ({
  courseScheduleId,
  ...externship
}: SiteSectionProps): React.ReactElement => {
  const featureFlags = useFeatureFlags();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { id, status, attestation, attestationStatus } = externship;
  const { addExternshipToState } = useCandidateDashboardContext();

  const [modalOpen, setModalOpen] = React.useState(false);

  const companyName =
    externship.company?.name ?? externship.proposedCompany?.location?.name;
  const location = getExternshipLocation(externship);

  const displayAttestationSection =
    featureFlags.EXTERNSHIPS &&
    !!attestation &&
    attestation.type.value !== AttestationType.OUT_OF_SCHOOL;

  const handleOpenModal = (): void => {
    setModalOpen(true);
  };

  const ActionComponent = (
    <CourseExternshipAction
      externshipId={id}
      enrollmentId={externship.enrollmentId!}
      courseId={courseScheduleId}
      status={status}
      companyName={companyName}
      handleOpenAgreementModal={handleOpenModal}
      revisedEmploymentStartDate={
        externship.externshipAgreement?.revisedEmploymentStartDate
      }
    />
  );

  return (
    <Box>
      <Styled.FlexRow flexWrap={isSmall ? 'wrap' : 'nowrap'}>
        <Styled.CompanyBox>
          <Typography variant="EC_TYPE_SM">{companyName}</Typography>
          <Typography variant="EC_TYPE_3XS">{location}</Typography>
          <ExternshipDetail label="Position" value={externship.jobTitle} />
          <ExternshipDetail
            label="Status"
            value={getExternshipStatusLabel(status)}
          />
          {!isSmall && ActionComponent}
        </Styled.CompanyBox>
        <Styled.ApproverBox>
          {!!externship.externshipToEmployers?.length && (
            <CourseExternshipDetails
              externship={externship}
            />
          )}
        </Styled.ApproverBox>
      </Styled.FlexRow>
      {isSmall && ActionComponent}
      {displayAttestationSection && (
        <Styled.FlexRow>
          <AttestationSection
            externshipId={id}
            attestation={attestation}
            attestationStatus={attestationStatus}
            companyName={companyName}
          />
        </Styled.FlexRow>
      )}
      {externship?.externshipAgreement && modalOpen && (
        <ExternshipAgreement
          externshipId={id}
          open={modalOpen}
          agreementTitle="Externship Agreement"
          handleClose={(): void => setModalOpen(false)}
          updateExternship={addExternshipToState}
          studentSigning={true}
        />
      )}
    </Box>
  );
};

interface Props {
  course: CourseWithExternships;
  enrollmentId: number;
  // expectedStartDate: string;
}

const StudentCourseCard: React.FC<Props> = ({
  course,
  enrollmentId
  // expectedStartDate
}) => {
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const history = useHistory();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { externships } = course;
  const clockHours = course.ClockHours ?? 0;

  const hours = React.useMemo(() => {
    let approved: string = '00:00';
    let submitted: string = '00:00';
    let approvedMins: number = 0;
    let pending: number = 0;

    const VALID_EXTERNSHIP_STATUSES_FOR_TIMESHEETS = [
      ExternshipStatus.ACTIVE,
      ExternshipStatus.NO_PASS,
      ExternshipStatus.COMPLETED
    ];

    if (externships?.length) {
      const allTimesheets = externships
        ?.filter((e) =>
          VALID_EXTERNSHIP_STATUSES_FOR_TIMESHEETS.includes(e.status)
        )
        .flatMap((e) => e.weeklyTimeSheets);

      if (allTimesheets?.length) {
        const {
          approvedTime,
          submittedTime,
          approvedMinutes,
          pendingProgress
        } = getTimesheetStatusHours(allTimesheets, clockHours);

        approved = approvedTime;
        submitted = submittedTime;
        approvedMins = approvedMinutes;
        pending = pendingProgress;
      }
    }

    return { approved, submitted, pending, approvedMins };
  }, [externships]);

  const proposalRoute = `/enrollment/${enrollmentId}/course/${course.Id}/externship/proposal`;
  const timesheetRoute = `/candidate/${candidateId}/course/${course.Id}/timesheets`;

  const cardButton = React.useMemo(() => {
    if (!externships?.length) {
      const handleClick = (): void => history.push(proposalRoute);

      return {
        label: 'Propose a location',
        // Do not allow user to propose if the course is ineligible
        disabled: CourseStatusesIneligibleForSiteProposal.includes(
          course.Status as CampusCourseStatus
        ),
        onClick: handleClick
      };
    }

    const activeExternship = externships?.find(
      (e) => e.status === ExternshipStatus.ACTIVE
    );
    const completedExternship = externships?.find(
      (e) => e.status === ExternshipStatus.COMPLETED
    );
    const noPassExternship = externships?.find(
      (e) => e.status === ExternshipStatus.NO_PASS
    );

    const handleTimesheetClick = (): void => history.push(timesheetRoute);

    if (activeExternship) {
      return {
        label: 'Submit Hours',
        icon: 'ri-upload-2-line',
        onClick: handleTimesheetClick
      };
    } else if (completedExternship || noPassExternship) {
      return {
        label: 'View Timesheets',
        icon: 'ri-file-text-line',
        onClick: handleTimesheetClick
      };
    } else {
      return undefined;
    }
  }, [externships, enrollmentId]);

  const courseTitle = `${course.CourseCode} - ${course.Name}`;
  const percentComplete = clockHours
    ? 100 * (hours.approvedMins / (clockHours * 60))
    : 0;
  const progressSubtext = `Approved ${hours.approved} / Submitted ${hours.submitted} / of ${clockHours}`;
  const statusForProgressBar = externships?.find(
    (e) =>
      e.status !== ExternshipStatus.ACTIVE &&
      e.status !== ExternshipStatus.COMPLETED
  )?.status;

  // Do not display course card if it is ineligible AND it has no externships associated with it
  if (cardButton?.disabled && !externships.length) {
    return <></>;
  }

  return (
    <Styled.Root>
      <Styled.FlexRow flexWrap="wrap" rowGap="12px">
        <Typography variant="EC_TYPE_LG">{courseTitle}</Typography>
        {cardButton && !cardButton?.disabled && (
          <Styled.CardBtn
            variant={isSmall ? 'outlined' : 'text'}
            endIcon={
              cardButton.icon ? <Icon className={cardButton.icon} /> : <></>
            }
            aria-label={cardButton.label}
            fullWidth={isSmall}
            onClick={cardButton.onClick}
          >
            {cardButton.label}
          </Styled.CardBtn>
        )}
      </Styled.FlexRow>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        {course.StartDate && (
          <Typography variant="EC_TYPE_XS">
            Start Date: {formatDate(course.StartDate)}
          </Typography>
        )}
        <Typography variant="EC_TYPE_XS">
          Course Status: {course.StatusName}
        </Typography>
        {course.LetterGrade && (
          <Typography variant="EC_TYPE_XS">
            Grade: {course.LetterGrade}
          </Typography>
        )}
      </Box>
      {/* Used while developing to see extra info */}
      {/* <Box sx={{ display: 'flex', gap: '12px' }}>
        <Typography variant="EC_TYPE_XS">
          Program:{' '}
          {
            (course.ProgramVersionName +
              ' ' +
              course.StudentEnrollmentPeriodId +
              ' ',
            enrollmentId)
          }
        </Typography>
        <Typography variant="EC_TYPE_XS">
          Expected Start: {formatDate(expectedStartDate)}
        </Typography>
      </Box> */}
      {!!externships.length && (
        <Styled.FlexRow maxWidth="315px" ml="auto" mt="12px">
          <EcProgressBar
            percentComplete={percentComplete}
            pendingProgress={hours.pending}
            subtext={progressSubtext}
            status={statusForProgressBar}
            maxWidth="315px"
          />
        </Styled.FlexRow>
      )}
      {!!externships.length && (
        <>
          <Styled.SiteSectionWrapper mt="24px" mb="16px">
            {externships?.map((externship) => (
              <SiteSection
                key={externship.id}
                {...externship}
                courseScheduleId={course.Id}
              />
            ))}
          </Styled.SiteSectionWrapper>
          {!cardButton?.disabled && (
            <Link
              component={RouterLink}
              to={proposalRoute}
              variant="EC_TYPE_SM"
              underline="none"
            >
              Propose another location
            </Link>
          )}
        </>
      )}
    </Styled.Root>
  );
};

export default StudentCourseCard;
