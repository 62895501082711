/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  ExternshipEntity,
  ExternshipProposedManagerOrSupervisor
} from '@api/models/externshipApi.models';
import {
  FieldErrors,
  useFieldArray,
  useForm,
  UseFormReturn
} from 'react-hook-form';
import { FormOrder, scrollToError } from '@common/helpers/scrollToError';
import { Button } from '@mui/material';
import ExternshipApproverFieldArray from '@components/Forms/ExternshipApproverFieldArray';
import { ProposedManagerAndSupervisorsPutReq } from '@api/models/externshipProposalApi.models';
import { setMinLengthError } from '@common/helpers/setFieldArrayMinLengthError';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';

const Styled = {
  Button: styled(Button, {
    shouldForwardProp: (prop) => prop !== 'verificationPage'
  })<{ verificationPage: boolean }>(({ verificationPage, theme }) => ({
    padding: 0,
    ...(!verificationPage && {
      transform: 'translate(24px, -32px)',
      [theme.breakpoints.up('sm')]: {
        transform: 'translate(74px, -32px)'
      }
    })
  })),
  BtnIcon: styled('i')({
    fontSize: '15px',
    lineHeight: '15px'
  })
};

type ControlledSupervisorFields = {
  id: string;
} & ExternshipProposedManagerOrSupervisor;

export interface ExternshipManagerFormValues
  extends ExternshipProposedManagerOrSupervisor {
  signingTimeCard: 0 | 1 | null;
  supervisors?: ExternshipProposedManagerOrSupervisor[];
}

export const useExternshipManagerForm = (
  handleValidFormRequest: (
    postBody: ProposedManagerAndSupervisorsPutReq,
    onSuccess?: () => void
  ) => Promise<void>
): {
  methods: UseFormReturn<ExternshipManagerFormValues, any>;
  supervisorsRequired: boolean;
  setSupervisorsRequired: React.Dispatch<React.SetStateAction<boolean>>;
  handleFormSubmit: (onSuccess?: () => void) => void;
} => {
  const [supervisorsRequired, setSupervisorsRequired] = React.useState(false);

  const methods = useForm<ExternshipManagerFormValues>({
    defaultValues: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      jobTitle: '',
      externshipRole: 'manager',
      signingTimeCard: null,
      supervisors: []
    }
  });

  const formOrder: FormOrder = {
    firstName: 10,
    lastName: 20,
    email: 30,
    phoneNumber: 40,
    jobTitle: 50,
    jobCategory: 60,
    supervisors: 70
  };

  const { formState, handleSubmit, setError } = methods;

  const handleInvalidForm = (errors: FieldErrors): void => {
    if (methods.getValues().supervisors?.length === 0 && supervisorsRequired) {
      setMinLengthError<ExternshipManagerFormValues>(
        'supervisors',
        'Please enter supervisor information',
        setError
      );
    }

    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (
    data: ExternshipManagerFormValues,
    onSuccess?: () => void
  ): Promise<void> => {
    let extraErrors = false;

    if (data.supervisors?.length === 0 && supervisorsRequired) {
      extraErrors = true;
      setMinLengthError<ExternshipManagerFormValues>(
        'supervisors',
        'Please enter supervisor information',
        setError
      );
    }

    if (extraErrors) {
      scrollToError(formState.errors, formOrder);
      return;
    }

    const postBody: ProposedManagerAndSupervisorsPutReq = {
      manager: {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        jobTitle: data.jobTitle,
        externshipRole: 'manager'
      },
      supervisors: data.supervisors?.map((s) => ({
        id: s.id,
        firstName: s.firstName,
        lastName: s.lastName,
        email: s.email,
        phoneNumber: s.phoneNumber,
        jobTitle: s.jobTitle,
        externshipRole: 'supervisor'
      }))
    };

    try {
      handleValidFormRequest(postBody, onSuccess);
    } catch (error: any) {
      console.error(
        `Error for ExternshipProposalManagerStep.handleValidForm(): ${error}`
      );

      const errorData = error.response?.data?.data;
      if (errorData?.length) {
        errorData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );

        scrollToError(formState.errors, formOrder);
      }
    }
  };

  const handleFormSubmit = (onSuccess?: () => void): void => {
    const onValid = async (data: ExternshipManagerFormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  return {
    methods,
    supervisorsRequired,
    setSupervisorsRequired,
    handleFormSubmit
  };
};

interface Props {
  methods: UseFormReturn<ExternshipManagerFormValues, any>;
  externship?: ExternshipEntity;
  supervisorsRequired: boolean;
  setSupervisorsRequired: React.Dispatch<React.SetStateAction<boolean>>;
  verificationPage?: boolean;
}

const ExternshipManagerForm: React.FC<Props> = ({
  methods,
  externship: draftExternship,
  setSupervisorsRequired,
  verificationPage
}) => {
  const defaultSupervisorFields = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
    jobCategory: 'Supervisor'
  };

  const { control, watch, reset, setValue, clearErrors } = methods;

  const { fields, append, remove } = useFieldArray({
    name: 'supervisors',
    control
  });

  React.useEffect(() => {
    console.log('draftExternship', draftExternship);

    if (draftExternship) {
      const { proposedCompany } = draftExternship;

      reset({
        id: proposedCompany?.manager?.id,
        firstName: proposedCompany?.manager?.firstName,
        lastName: proposedCompany.manager?.lastName,
        email: proposedCompany.manager?.email,
        phoneNumber: proposedCompany.manager?.phoneNumber,
        jobTitle: proposedCompany.manager?.jobTitle,
        signingTimeCard: proposedCompany.supervisors?.length ? 0 : 1,
        supervisors: proposedCompany.supervisors
      });

      setSupervisorsRequired(
        proposedCompany.supervisors?.length ? true : false
      );
    }
  }, [draftExternship]);

  const watchSigningTimeCard = watch('signingTimeCard');
  const watchSupervisorFieldArray = watch('supervisors') || [];
  const controlledSupervisorFields: ControlledSupervisorFields[] = fields.map(
    (field, idx) => {
      return {
        ...field,
        ...watchSupervisorFieldArray[idx]
      };
    }
  );

  const addNewSupervisor = (): void => {
    setSupervisorsRequired(true);
    append(defaultSupervisorFields);
  };

  const handleRemoveSupervisor = (idx: number): void => {
    if (controlledSupervisorFields.length === 1) {
      setValue('signingTimeCard', null);
      setSupervisorsRequired(false);
    }
    remove(idx);
  };

  const removeAllSupervisors = (): void => {
    setValue('supervisors', []);
    setSupervisorsRequired(false);
    clearErrors('supervisors');
  };

  React.useEffect(() => {
    if (watchSigningTimeCard === 0 && controlledSupervisorFields?.length < 1) {
      addNewSupervisor();
    }

    if (watchSigningTimeCard === 1 && !!controlledSupervisorFields?.length) {
      removeAllSupervisors();
    }
  }, [watchSigningTimeCard, controlledSupervisorFields]);

  React.useEffect(() => {
    setValue('jobCategory', 'Manager');
  }, []);

  const padding = verificationPage ? '0px' : undefined;

  return (
    <>
      <StepSection
        title="Manager Information"
        dataTestId="externship-proposed-manager-form"
        padding={padding}
        disableBoxShadow
      >
        <ExternshipApproverFieldArray
          isManagersVerificationPage={!!verificationPage}
        />
      </StepSection>
      {!!controlledSupervisorFields?.length && (
        <>
          <StepSection
            title="Supervisor Information"
            dataTestId="externship-proposed-supervisors-form"
            padding={padding}
            disableBoxShadow
          >
            {controlledSupervisorFields.map((field, idx) => (
              <StepSubSection key={field.id}>
                <ExternshipApproverFieldArray
                  isSupervisor
                  fieldArrayIndex={idx}
                  handleRemoveFieldArrayItem={handleRemoveSupervisor}
                />
              </StepSubSection>
            ))}
          </StepSection>
          <Styled.Button
            variant="text"
            onClick={addNewSupervisor}
            startIcon={<Styled.BtnIcon className="ri-add-box-line" />}
            verificationPage={!!verificationPage}
          >
            Add a supervisor
          </Styled.Button>
        </>
      )}
    </>
  );
};

export default ExternshipManagerForm;
