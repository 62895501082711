import * as React from 'react';
import ExternshipManagerForm, {
  useExternshipManagerForm
} from './ExternshipManagerForm';
import { ExternshipApi } from '@api/Externships.api';
import ExternshipProposalStepLayout from '@pages/Candidate/ExternshipProposalPage/ExternshipProposalStepLayout';
import { ProposedManagerAndSupervisorsPutReq } from '@api/models/externshipProposalApi.models';
import { useFetchExternships } from '@common/fetches/useFetchExternships';
import { useParams } from 'react-router-dom';
import { useStepperContext } from '@common/context/stepperContext';

interface Props {
  handleStepper: (next: boolean) => void;
  candidateId: number;
  proposedCompanyId?: number;
  externshipId: number | null;
}

const ExternshipProposalManagerStep: React.FC<Props> = ({
  handleStepper,
  candidateId,
  proposedCompanyId,
  externshipId
}) => {
  const { stepperLoading, setStepperLoading } = useStepperContext();

  const [errMsg, setErrMsg] = React.useState<string>();

  const { externshipId: paramsExternshipId } = useParams<{
    externshipId?: string;
  }>();

  const { externshipById: draftExternship } = useFetchExternships(
    null,
    paramsExternshipId ?? externshipId
  );

  const handleValidFormRequest = async (
    postBody: ProposedManagerAndSupervisorsPutReq,
    onSuccess?: () => void
  ): Promise<void> => {
    try {
      setStepperLoading(true);

      if (proposedCompanyId) {
        if (!draftExternship) {
          await ExternshipApi.updateManagerAndSupervisors(postBody, {
            candidateId,
            proposedCompanyId
          });
          !!onSuccess && onSuccess();
        } else {
          await ExternshipApi.updateManagerAndSupervisors(postBody, {
            externshipId: draftExternship.id
          });
          !!onSuccess && onSuccess();
        }
      }
    } catch (error: any) {
      setErrMsg(error?.response?.data?.message || error?.message);
    } finally {
      setStepperLoading(false);
    }
  };

  const {
    methods,
    supervisorsRequired,
    setSupervisorsRequired,
    handleFormSubmit
  } = useExternshipManagerForm(handleValidFormRequest);

  return (
    <ExternshipProposalStepLayout
      methods={methods}
      content={
        <ExternshipManagerForm
          methods={methods}
          externship={draftExternship}
          supervisorsRequired={supervisorsRequired}
          setSupervisorsRequired={setSupervisorsRequired}
        />
      }
      formActions={{
        onSubmit: handleFormSubmit,
        submitBtnText: 'Save and Continue',
        handleStepper: handleStepper,
        errorMessage: errMsg,
        disableSubmit: stepperLoading
      }}
    />
  );
};

export default ExternshipProposalManagerStep;
