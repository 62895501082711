import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  ExternshipEntity,
  ExternshipRole
} from '@api/models/externshipApi.models';
import { styled } from '@mui/system';
import { CheckCircle } from '@mui/icons-material';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.GRAY_5.main}`,
    padding: '8px',
    '& .detail-title': {
      marginBottom: '8px'
    }
  })),
  Row: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start'
  }),
  DetailBox: styled(Box)({
    display: 'flex',
    columnGap: '48px'
  }),
  SupervisorBox: styled(Box)({
    display: 'flex',
    columnGap: '12px',
    flexWrap: 'wrap',
    '& .supervisor-info:not(:last-of-type)': {
      marginBottom: '8px'
    }
  })
};

interface Props {
  externship: Omit<ExternshipEntity, 'course'>;
}

interface ContactProps {
  name: string;
  phone: string;
  email: string;
}

const ContactInfo = ({ name, phone, email }: ContactProps): JSX.Element => {
  return (
    <>
      <Typography variant="EC_TYPE_3XS">{name}</Typography>
      <Typography variant="EC_TYPE_3XS">{phone}</Typography>
      <Typography variant="EC_TYPE_3XS">{email}</Typography>
    </>
  );
};

const CourseExternshipDetails: React.FC<Props> = ({ externship }) => {
  const manager = externship.externshipToEmployers.find((link) => link.externshipRole === ExternshipRole.MANAGER)?.employer;
  const supervisors = externship.externshipToEmployers.filter((link) => link.externshipRole !== ExternshipRole.MANAGER)
    .map(link => ({ ...link.employer, externshipRole: link.externshipRole }) );

  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_2XS" className="detail-title">
        APPROVERS:
      </Typography>
      <Styled.Row>
        <Styled.DetailBox>
          {!!manager && (
            <Box>
              <Typography variant="EC_TYPE_3XS" fontWeight={700} mb="4px">
                Manager Contact
              </Typography>
              <ContactInfo
                name={`${manager.firstName} ${manager.lastName}`}
                phone={manager.phone}
                email={manager.email}
              />
            </Box>
          )}
          {!!supervisors?.length && (
            <Box>
              <Typography variant="EC_TYPE_3XS" fontWeight={700} mb="4px">
                Approver Contact(s)
              </Typography>
              <Styled.SupervisorBox>
                {supervisors.map((supervisor) => (
                  <Box key={supervisor.email} className="supervisor-info">
                    <ContactInfo
                      name={`${supervisor.firstName} ${supervisor.lastName}`}
                      phone={supervisor.phone}
                      email={supervisor.email}
                    />
                    {supervisor.externshipRole === ExternshipRole.MENTOR &&
                      <>
                        <CheckCircle color="primary" 
                          sx={{
                            display: 'inline-block',
                            paddingTop: '.2em',
                            fontSize: '.8em',
                          }}
                        />
                        <Typography 
                          sx={{ 
                            display: 'inline-block',
                            fontWeight: 'bold',
                            marginBottom: '.2em',
                          }} 
                          variant="EC_TYPE_3XS"
                        >
                          Mentor
                        </Typography>
                      </>
                    }
                  </Box>
                ))}
              </Styled.SupervisorBox>
            </Box>
          )}
        </Styled.DetailBox>
      </Styled.Row>
    </Styled.Root>
  );
};
export default CourseExternshipDetails;
