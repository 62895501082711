import * as React from 'react';
import { Box, Container, Skeleton, Typography } from '@mui/material';
import Hero, { ComponentSize } from '@components/Hero';
import { getFilteredSearchResults } from '@common/helpers/getFilteredSearchResults';
import HeroImage from '@assets/PartnerSignInPage/hero_image.jpg';
import { Partner } from '@api/models/partnerApi.models';
import PartnersTable from './PartnersTable';
import { Redirect } from 'react-router-dom';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import TableWrapperWithSort from '@components/TableWrapperWithSort';
import theme from '@themes/ui/escoffier';
import { useFetchPartners } from '@common/fetches/useFetchPartners';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  ContentWrapper: styled(Container)({
    marginTop: '64px'
  }),
  WelcomeWrapper: styled(Box)({
    textAlign: 'center'
  }),
  TableWrapper: styled(Box)({
    marginTop: '38px'
  }),
  PartnerTableSkeleton: styled(Skeleton)({
    maxWidth: '500px',
    margin: '0 auto',
    marginTop: '48px',
    borderRadius: '4px'
  })
};

type SearchParams = (keyof Partner)[];

const PartnerSignInPage: React.FC = () => {
  const { isAuthenticated, isGlobalSolutions: isPartner } = useKeycloakContext();

  if (isPartner) {
    return <Redirect to="/learning-center" />;
  }

  if (isAuthenticated && !isPartner) {
    return <Redirect to="/not-found" />;
  }

  const { partners, loading } = useFetchPartners();
  const [localPartners, setLocalPartners] = React.useState<Partner[]>([]);

  const [searchValue, setSearchValue] = React.useState<string>('');
  const searchParams: SearchParams = ['name'];

  React.useEffect(() => {
    setLocalPartners(partners);
  }, [partners]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const clearSearch = (): void => setSearchValue('');

  const searchBarProps = {
    onChange: onSearchChange,
    clearSearch,
    value: searchValue,
    isCentered: true
  };

  React.useEffect(() => {
    if (searchValue === '') {
      setLocalPartners(partners);
    }

    const filteredPartners = getFilteredSearchResults(
      partners,
      searchParams,
      searchValue
    );

    setLocalPartners(filteredPartners);
  }, [searchValue]);

  return (
    <RootPageLayout muiMaxWidth={false} padding="0px">
      <Hero
        size={ComponentSize.MEDIUM}
        imgUrl={HeroImage}
        title="ESource"
        subtitle="Powered by Auguste Escoffier School of Culinary Arts."
      />
      <Styled.ContentWrapper>
        <Styled.WelcomeWrapper>
          <Typography
            variant="EC_TYPE_4XL"
            sx={{ color: theme.palette.GOLD_1.main }}
          >
            Welcome to Escoffier Partner Portal!
          </Typography>
          <Typography
            variant="EC_TYPE_BASE"
            sx={{ color: theme.palette.GRAY_4.main }}
          >
            Select your employer to get started.
          </Typography>
        </Styled.WelcomeWrapper>
        <Styled.TableWrapper>
          <TableWrapperWithSort
            sessionKey="PARTNERS_SIGN_IN_TABLE"
            initialRowsPerPage={5}
            initialSortCategory="name"
            initialSortOrder="ASC"
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            totalCount={localPartners.length}
            displayMobile={false}
            centerPagination
            searchBarProps={searchBarProps}
          >
            {loading ? (
              <Styled.PartnerTableSkeleton variant="rectangular" height={475} />
            ) : (
              <PartnersTable partners={localPartners} />
            )}
          </TableWrapperWithSort>
        </Styled.TableWrapper>
      </Styled.ContentWrapper>
    </RootPageLayout>
  );
};

export default PartnerSignInPage;
