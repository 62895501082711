import * as React from 'react';
import {
  SeatRoleSelectOption
} from '@api/models/userApi.models';
import { Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { emailPattern } from '@common/regex/email';
import { InviteApi } from '@api/Invite.api';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
// TODO: use multi-select instead of select once we are using multiple roles
// import ReactHookFormsMultiSelect from '@components/Forms/ReactHookFormsMultiSelect';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import { SendInviteRequest } from '@api/models/inviteApi.models';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { EmployerRole } from '@api/models/employerApi.models';

const Styled = {
  Button: styled(Button)(({ theme }) => ({
    whiteSpace: 'nowrap',
    height: '45px',
    width: 'auto',
    '& .invite-btn-text': {
      fontSize: '1em',
      color: theme.palette.WHITE.main,
      fontWeight: 600
    },
    ['@media (max-width:600px)']: {
      width: '100%'
    }
  })),
  FormContainer: styled(Grid)(() => ({
    marginBottom: '2em',
  }))
};

interface Props {
  companyId: number;
  refreshSentInvites: () => void;
  openToast: (msg: string) => void;
  refreshSeatsData?: () => void;
}

const InviteUserBox: React.FC<Props> = ({
  companyId,
  refreshSentInvites,
  openToast,
  refreshSeatsData,
}) => {
  const [inviteSent, setInviteSent] = React.useState<boolean>(false);

  const featureFlags = useFeatureFlags();

  const defaultEmployerRole = EmployerRole.RECRUITER;

  const methods = useForm<SendInviteRequest>({
    defaultValues: { 
      companyId, 
      email: '', 
      employerRole: defaultEmployerRole,
    }
  });

  const { handleSubmit, reset } = methods;

  const employerRoleSelectOptions: SeatRoleSelectOption<EmployerRole>[] = 
  [EmployerRole.EMPLOYEE, EmployerRole.RECRUITER, EmployerRole.ESOURCE_USER].map((role) => ({
    label: role,
    value: role,
  }));

  const onValid = async (data): Promise<void> => {
    const postBody = {
      companyId,
      email: data.email,
      employerRole: data.employerRole ?? EmployerRole.EMPLOYEE,
    } as SendInviteRequest;

    let exceptionThrown = false;
    try {
      await InviteApi.sendInvite(postBody);
      setInviteSent(true);
      refreshSentInvites();
      if (refreshSeatsData) {
        refreshSeatsData();
      }
    } catch (error: any) {
      exceptionThrown = true;
      console.error('Error for InviteApi.sendInvite()', error);
      openToast(`Error sending invite: ${error?.response?.data?.message ?? error.message}`);
    } finally {
      if (!exceptionThrown) {
        openToast('Invite Sent');
      }
    }
  };

  React.useEffect(() => {
    if (inviteSent) {
      reset();
      setInviteSent(false);
    }
  }, [inviteSent]);

  return (
    <FormProvider {...methods}>
      <Styled.FormContainer container spacing={1} data-testid="invite-user-box-root">
        <Grid item xs={8} sm={6}>
          <ReactHookFormsInput
            name="email"
            placeholder="Add user email"
            dataTestId="invite-user-box-input"
            disableRequiredAsterisk
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid email'
              }
            }}
          />
        </Grid>
        {featureFlags.PARTNER_EXPERIENCE && (
          <Grid item xs={4} sm={3}>
            <ReactHookFormsSelect
              name="employerRole"
              options={employerRoleSelectOptions}
              placeholder="Role"
              rules={{ required: true }}
              dataTestId="invite-user-box-select"
              disableRequiredAstrisk
            />
            {/* <ReactHookFormsMultiSelect name="roles" options={roleOptions} /> */}
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <Styled.Button
            variant="contained"
            onClick={handleSubmit(onValid)}
            data-testid="invite-user-box-button"
            fullWidth
          >
            <Typography variant="EC_TYPE_2XS" className="invite-btn-text">
              Invite New User
            </Typography>
          </Styled.Button>
        </Grid>
      </Styled.FormContainer>
    </FormProvider>
  );
};

export default InviteUserBox;
