import * as React from 'react';
import {
  activeEmployerNav,
  candidateNav,
  careerServicesNav,
  chefInstructorNav,
  escoffierAdminNav,
  timesheetApproverNav,
  inactiveEmployerNav,
  limboSsoNav,
  partnerNav,
  pendingUserNav,
  unauthenticatedNav,
  commonLinks,
} from '@components/Layout/Header/NavigationLists';
import {
  authorizedUserSettingsNav,
  candidateSettingsNav,
  employerSettingsNav,
  escoffierAdminSettingsNav,
  recruiterSettingsNav,
  timesheetApproverSettingsNav,
} from '@pages/Shared/SettingsPage/SettingsNavLinks';
import { store, useStore } from 'react-context-hook';
import { useMediaQuery, useTheme } from '@mui/material';
import { contentManagementNavLinks } from '@pages/EcAdmin/ContentManagementPage/ContentManagementPage';
import DesktopNav from '@components/Layout/Header/DesktopNav';
import { loginWithStorageRedirect } from '@common/helpers/login';
import MobileNav from '@components/Layout/Header/MobileNav';
import ResponsiveLayout from '../ResponsiveLayout';
import { useDeleteStoreValue } from 'react-context-hook';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useKeycloak } from '@react-keycloak/web';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';
import { AvatarMenuItem, NavigationHeader, NavItem } from './Header.models';

const Header: React.FC = () => {
  const { sendToGtm } = useTagManager();
  const featureFlags = useFeatureFlags();

  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);

  const theme = useTheme();
  const isAtOrBelowHeaderBreakpoint = useMediaQuery(
    theme.breakpoints.down('headerMobile')
  );

  const deleteManagedCompanies = useDeleteStoreValue(UseStoreKeys.MANAGED_COMPANIES);
  const deleteCandidateId = useDeleteStoreValue(UseStoreKeys.CANDIDATE_ID);

  const [isEnabledCompany] = useStore(UseStoreKeys.COMPANY_ENABLED);
  const [isParentCompany] = useStore<boolean>(UseStoreKeys.IS_PARENT_COMPANY);

  const { keycloak } = useKeycloak();
  const {
    isAuthenticated,
    isCandidate,
    isEcAdmin,
    isEcCareerService,
    isExternshipTimesheetApprover,
    isEmployer,
    isRecruiter,
    isGlobalSolutions: isPartner,
    isGlrcUser,
    isPendingUser,
    isChefInstructor,
    keycloakUser,
  } = useKeycloakContext();

  // User is in limbo state if they are an authenticated user with no roles
  const hasNoRoles = !!keycloakUser && !keycloakUser?.roles?.length;

  const isDev = process.env.REACT_APP_FEATURE_FLAG_ENV === 'dev';

  const concatenateNavigationHeaderLinks = (
    navHeader1: NavigationHeader,
    navHeader2: NavigationHeader): NavigationHeader => {
    return {
      primaryLinks: new Set([...navHeader1.primaryLinks, ...navHeader2.primaryLinks]),
      avatarLinks: new Set([...navHeader1.avatarLinks, ...navHeader2.avatarLinks]),
      settingsLinks: navHeader1.settingsLinks && navHeader2.settingsLinks ? 
        new Set([...navHeader1.settingsLinks, ...navHeader2.settingsLinks]) : 
        undefined,
      contentManagementLinks: navHeader1.contentManagementLinks && navHeader2.contentManagementLinks ?
        new Set([...navHeader1.contentManagementLinks, ...navHeader2.contentManagementLinks]) :
        undefined,
    } as NavigationHeader;
  };

  const [navHeaderData, setNavHeaderData] = React.useState<NavigationHeader>({ 
    primaryLinks: new Set<NavItem>(), 
    avatarLinks: new Set<AvatarMenuItem>(), 
    settingsLinks: new Set<NavItem>(), 
    contentManagementLinks: new Set<NavItem>(), 
  });

  const getNavHeaderData = (): NavigationHeader => {
    let newNavHeaderData: NavigationHeader = {
      primaryLinks: new Set<NavItem>(), 
      avatarLinks: new Set<AvatarMenuItem>(), 
      settingsLinks: new Set<NavItem>(), 
      contentManagementLinks: new Set<NavItem>(), 
    } as NavigationHeader;

    let newSettingsLinks: Set<NavItem> = new Set(authorizedUserSettingsNav);
    let newContentManagementLinks: Set<NavItem> = new Set(); 

    // Return a fixed set of links for these conditions
    if (!isAuthenticated) {
      return unauthenticatedNav;
    }
    if (isPendingUser) {
      return pendingUserNav;
    } 
    if (hasNoRoles) {
      return limboSsoNav;
    }
    if (isPartner || isGlrcUser) {
      return partnerNav();
    }

    // Allow a user with multiple roles to have a union of all header nav links
    if (isCandidate) {
      newNavHeaderData = concatenateNavigationHeaderLinks(newNavHeaderData, candidateNav(candidateId));
      newSettingsLinks = new Set([...newSettingsLinks, ...candidateSettingsNav]);
      console.debug('Header: added Candidate links...', newNavHeaderData);
    } 
    if (isExternshipTimesheetApprover && !isEmployer) {
      newNavHeaderData = concatenateNavigationHeaderLinks(newNavHeaderData, timesheetApproverNav);
      newSettingsLinks = new Set([...newSettingsLinks, ...timesheetApproverSettingsNav]);
      console.debug('Header: added ExternshipTimesheetApprover links...', newNavHeaderData);
    }
    if (isEmployer) {
      const tempNavHeaderData = isEnabledCompany
        ? activeEmployerNav(isParentCompany)
        : inactiveEmployerNav;
      newSettingsLinks = new Set([...newSettingsLinks, ...employerSettingsNav()]);
      newNavHeaderData = concatenateNavigationHeaderLinks(newNavHeaderData, tempNavHeaderData);
      console.debug('Header: added Employer links...', newNavHeaderData);
    } 
    if (isRecruiter) {
      const tempNavHeaderData = isEnabledCompany
        ? activeEmployerNav(isParentCompany)
        : inactiveEmployerNav;
      newNavHeaderData = concatenateNavigationHeaderLinks(newNavHeaderData, tempNavHeaderData);
      newSettingsLinks = new Set([...newSettingsLinks, ...recruiterSettingsNav]);
      console.debug('Header: added Recruiter links...', newNavHeaderData);
    } 
    if (isEcAdmin) {
      newNavHeaderData = concatenateNavigationHeaderLinks(newNavHeaderData, escoffierAdminNav());
      newSettingsLinks = new Set([...newSettingsLinks, ...escoffierAdminSettingsNav]);
      const newLinks = featureFlags.CONTENT_MANAGEMENT ? contentManagementNavLinks() : [];
      newContentManagementLinks = new Set([...newContentManagementLinks, ...newLinks]);
      console.debug('Header: added Admin links...', newNavHeaderData);
    } 
    if (isEcCareerService) {
      newNavHeaderData = concatenateNavigationHeaderLinks(newNavHeaderData, careerServicesNav(isDev));
      console.debug('Header: added Career Services links...', newNavHeaderData);
    } 
    if (isChefInstructor) {
      newNavHeaderData = concatenateNavigationHeaderLinks(newNavHeaderData, chefInstructorNav);
      console.debug('Header: added Chef Instructor links...', newNavHeaderData);
    } 
    
    newNavHeaderData.settingsLinks = newSettingsLinks;
    newNavHeaderData.contentManagementLinks = newContentManagementLinks;
    
    // Reorder links: move any links found in commonLinks to the end
    const reorderLinks = (linksSet: Set<NavItem>): Set<NavItem> => {
      const commonPaths = new Set(Object.values(commonLinks).map(link => link.path));
      const nonCommon: NavItem[] = [];
      const common: NavItem[] = [];
      linksSet.forEach(item => {
        if (item.path && commonPaths.has(item.path)) {
          common.push(item);
        } else {
          nonCommon.push(item);
        }
      });
      return new Set([...nonCommon, ...common]);
    };

    newNavHeaderData.primaryLinks = reorderLinks(newNavHeaderData.primaryLinks);
    // Apply only to sets of NavItem (skip avatarLinks since they're AvatarMenuItem)
    newNavHeaderData.settingsLinks && (newNavHeaderData.settingsLinks = reorderLinks(newNavHeaderData.settingsLinks));
    newNavHeaderData.contentManagementLinks && (newNavHeaderData.contentManagementLinks = reorderLinks(newNavHeaderData.contentManagementLinks));

    return newNavHeaderData;
  };

  React.useEffect(() => {
    const newNavHeaderData = getNavHeaderData();
    setNavHeaderData(newNavHeaderData);
  }, [keycloakUser]);

  React.useEffect(() => {
    if (isAtOrBelowHeaderBreakpoint && isAuthenticated) {
      // Add settings to currNav
      
      navHeaderData.settingsLinks = new Set(Array.from(navHeaderData.settingsLinks ?? [])
        .map((item) => ({
          ...item,
          path: `/settings${item.path}`
        })));

      navHeaderData.contentManagementLinks = new Set(Array.from(navHeaderData.contentManagementLinks ?? [])
        .map((item) => ({
          ...item,
          path: `/admin/content-management${item.path}`
        })));
    }
  }, [isAtOrBelowHeaderBreakpoint, isAuthenticated]);

  const logout = React.useCallback(() => {
    sendToGtm('navigation', {
      navigate_to: 'sign-out',
      component: 'header',
      link_text: 'Sign Out'
    });

    // NOTE Per lib documentation, these empty strings are not required,
    // but TS is complaining without them.
    deleteManagedCompanies('');
    deleteCandidateId('');
    store.reset({});
    keycloak.logout({
      redirectUri: `${window.location.origin}/logout`
    });
  }, [keycloak]);

  const login = React.useCallback(() => {
    sendToGtm('navigation', {
      navigate_to: 'sign-in',
      component: 'header',
      link_text: 'Sign In'
    });
    loginWithStorageRedirect(keycloak.login);
  }, [keycloak.login]);

  return (
    <ResponsiveLayout
      useMobileContainer={false}
      customBreakpoint={1019}
      desktopComponent={
        <DesktopNav
          nav={navHeaderData}
          keycloakUser={keycloakUser}
          login={login}
          logout={logout}
          isAuthenticated={!!isAuthenticated}
        />
      }
      mobileComponent={
        <MobileNav
          nav={navHeaderData}
          login={login}
          logout={logout}
          isAuthenticated={!!isAuthenticated}
        />
      }
    />
  );
};

export default Header;
