/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import {
  ApproverRole,
  ExternshipEntity,
  ExternshipStatus,
  SiteAgreementData
} from '@api/models/externshipApi.models';
import {
  formatDate,
  newFormatDate
} from '@common/helpers/dateHelpers/formatDate';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import EcDialog from '@components/EcDialog';
import ExternshipAgreement from '@components/ExternshipAgreements/ExternshipAgreement';
import { getExternshipStatusLabel } from '@common/helpers/externshipHelpers/getExternshipStatusLabel';
import { getFilePath } from '@common/helpers/getFilePath';
import ProxyAgreement from '@components/ExternshipAgreements/ProxyAgreement';
import { Link as RouterLink } from 'react-router-dom';
import SiteAgreementNotSignedModal from './SiteAgreementNotSignedModal';
import { styled } from '@mui/system';
import { WHITE } from '@themes/ui/escoffier';
import { EmployerProfile, EmployerRole } from '@api/models/employerApi.models';

type StyledButtonProps = ButtonProps & {
  component?: React.ElementType;
  to?: string;
};

const Styled = {
  Root: styled(Box)({
    padding: '16px'
  }),
  NameAndStatusSection: styled(Box)({
    display: 'flex',
    columnGap: '24px',
    rowGap: '8px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '@media (max-width:700px)': {
      flexDirection: 'column'
    }
  }),
  ExternshipDetailsSection: styled(Box)({
    display: 'flex',
    columnGap: '24px',
    rowGap: '8px',
    flexWrap: 'wrap'
  }),
  AgreementCtaSection: styled(Box)({
    display: 'flex',
    gap: '24px',
    width: '100%',
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  }),
  CtaSection: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isSpaceBetween'
  })<{ isSpaceBetween: boolean }>(({ isSpaceBetween }) => ({
    display: 'flex',
    justifyContent: isSpaceBetween ? 'space-between' : 'flex-end',
    alignItems: 'flex-end',
    flexShrink: 0,
    marginTop: '24px',
    gap: '24px',
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  })),
  CtaButton: styled(Button)<StyledButtonProps>(() => ({
    minWidth: 'max-content',
    '@media (max-width:900px)': {
      width: '100%'
    }
  })),
  StepWrapper: styled(Box)({
    display: 'flex',
    gap: '8px'
  }),
  StepNumber: styled(Typography)({
    color: 'black',
    marginBottom: '0px',
    lineHeight: '38px'
  })
};

interface Props extends ExternshipEntity {
  employer: EmployerProfile;
  siteAgreementPath?: string;
  handleRejectExternship: (externshipId: number) => Promise<void>;
  isMobileView?: boolean;
  handleOpenSiteAgreementModal: () => void;
  siteAgreementData?: SiteAgreementData;
}

const ExternshipsTableRowRedesign = ({
  employer,
  handleRejectExternship,
  handleOpenSiteAgreementModal,
  siteAgreementData,
  ...externshipData
}: Props): React.ReactElement => {
  const [externship, setExternship] =
    React.useState<ExternshipEntity>(externshipData);

  // Modals open/close
  const [confirmRejectModalOpen, setConfirmRejectModalOpen] = React.useState(false);
  const [openSiteAgreementNotSignedModal, setOpenSiteAgreementNotSignedModal] = React.useState(false);
  const [openExternshipAgreementModal, setOpenExternshipAgreementModal] = React.useState(false);
  const [openProxyAgreementModal, setOpenProxyAgreementModal] = React.useState(false);
  const [canUserSignSiteAgreement, setCanUserSignSiteAgreement] = React.useState(false);
  const [canUserSignExternshipAgreement, setCanUserSignExternshipAgreement] = React.useState(false);

  const currentUserId = employer?.user?.id;

  const {
    id: externshipId,
    status,
    jobTitle,
    candidate: { firstName, lastName },
    course,
    company,
    proposedCompany,
  } = externship;

  React.useEffect(() => {
    const employerToCompanyLink = employer?.companyToEmployers?.filter(link => link.company?.id == company?.id);
    if ((employerToCompanyLink ?? []).length > 0) {
      setCanUserSignSiteAgreement(employerToCompanyLink![0]?.employerRole === EmployerRole.COMPANY_ADMIN);
    }
    const externshipManager = externship.approvers?.find(link => link.approverRole === ApproverRole.MANAGER);
    setCanUserSignExternshipAgreement(externshipManager?.user?.id === currentUserId);
  }, [employer, company]);

  const companyAdminLinks = employer.companyToEmployers?.filter(link => 
    link.employerRole === EmployerRole.COMPANY_ADMIN
  );

  const isCompanyAdmin = !!companyAdminLinks?.length;  

  const managerLink = externship?.approvers?.find(link =>
    link.approverRole === ApproverRole.MANAGER && link?.user?.id === employer?.user?.id
  );

  const supervisorLink = externship?.approvers?.find(link => 
    link.approverRole !== ApproverRole.MANAGER && link?.user?.id === employer?.user?.id
  );

  const displayProxyAgreement = !!supervisorLink && !supervisorLink.proxyAgreement;

  const currentUserCanApproveTimesheets = managerLink ? managerLink.canApproveTimesheets : supervisorLink?.canApproveTimesheets;

  const VALID_EXTERNSHIP_STATUSES_FOR_TIMESHEET_REVIEW = [
    ExternshipStatus.ACTIVE,
    ExternshipStatus.COMPLETED,
    ExternshipStatus.NO_PASS
  ];
  const displayReviewTimesheets =
    currentUserCanApproveTimesheets &&
    VALID_EXTERNSHIP_STATUSES_FOR_TIMESHEET_REVIEW.includes(status);

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const siteAgreementPdfPath =
    !siteAgreementData?.agreementNeeded && siteAgreementData?.agreementFullName
      ? `${BASE_URL}/${getFilePath(siteAgreementData?.agreementFullName)}`
      : undefined;

  // Display reject button if user is externship manager and externship is in pending stage
  const displayRejectBtn = React.useMemo(() => {
    const revisedEmploymentStartDate =
      externship.externshipAgreement?.revisedEmploymentStartDate;
    if (
      isCompanyAdmin &&
      !revisedEmploymentStartDate &&
      status === ExternshipStatus.PENDING_EXT_MNG_AGREEMENT
    ) {
      return true;
    }

    return false;
  }, [isCompanyAdmin, status]);

  // Do not display the site or externship agreement if the externship has one of the following statuses
  const INVALID_AGREEMENT_STATUSES = [
    ExternshipStatus.PENDING,
    ExternshipStatus.PENDING_SOFT_VERIFICATION
  ];

  const displaySiteAndEaAgreement =
    !!managerLink && !INVALID_AGREEMENT_STATUSES.includes(status);

  const _status = getExternshipStatusLabel(status);

  const handleRejectClick = async (): Promise<void> => {
    await handleRejectExternship(externshipId);
  };

  const handleUpdateExternship = (externship: ExternshipEntity): void => {
    setExternship(externship);
  };

  const openSiteAgreementPdf = (): void => {
    window.open(siteAgreementPdfPath);
  };

  const openEaPdf = (): void => {
    if (externship.externshipAgreement?.file.fullName) {
      window.open(
        `${BASE_URL}/files/${encodeURIComponent(
          externship.externshipAgreement?.file.fullName
        )}`
      );
    }
  };

  const signedExternshipAgreement =
    !!externship.externshipAgreement?.managerSignDate;
  const managerEaSignDate = externship.externshipAgreement?.managerSignDate;

  return (
    <>
      <Styled.Root>
        <BottomMarginContentWrapper bottomMargin="8px">
          {/* top row first name and status */}
          <Styled.NameAndStatusSection>
            <Typography
              variant="EC_TYPE_LG"
              color={'primary'}
              data-testid="externships-table-candidate-name"
            >
              {firstName} {lastName}
            </Typography>
            <Typography
              variant="EC_TYPE_SM"
              data-testid="externships-table-status"
            >
              Status: {_status}
            </Typography>
          </Styled.NameAndStatusSection>
          {/* externship details */}
          <Styled.ExternshipDetailsSection>
            <Typography
              variant="EC_TYPE_SM"
              data-testid="externships-table-company-name"
            >
              Company:&nbsp; {company?.name || proposedCompany?.location?.name}
            </Typography>
          </Styled.ExternshipDetailsSection>
          <Styled.ExternshipDetailsSection>
            <Typography
              variant="EC_TYPE_SM"
              data-testid="externships-table-job-title"
            >
              Position:&nbsp; {jobTitle}
            </Typography>
            {externship?.externshipAgreement?.employmentStartDate && (
              <Typography
                variant="EC_TYPE_SM"
                data-testid="externships-table-position-start"
              >
                Position Start:&nbsp;
                {newFormatDate(
                  externship?.externshipAgreement?.employmentStartDate
                )}
              </Typography>
            )}
            <Typography variant="EC_TYPE_SM">
              Course:&nbsp; {course.Code}
            </Typography>
            {externship?.scheduledStartDate && (
              <Typography
                variant="EC_TYPE_SM"
                data-testid="externships-table-course-start"
              >
                Course Start:&nbsp;
                {newFormatDate(externship.scheduledStartDate)}
              </Typography>
            )}
          </Styled.ExternshipDetailsSection>
        </BottomMarginContentWrapper>
        {/* all ctas section */}
        <Styled.CtaSection isSpaceBetween={displaySiteAndEaAgreement}>
          {/* agreements cta section */}
          {displaySiteAndEaAgreement && (
            <Styled.AgreementCtaSection>
              <Styled.StepWrapper>
                <Styled.StepNumber variant="EC_TYPE_3XL">1.</Styled.StepNumber>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={siteAgreementData?.agreementNeeded && !canUserSignSiteAgreement}
                  onClick={
                    siteAgreementPdfPath
                      ? openSiteAgreementPdf
                      : handleOpenSiteAgreementModal
                  }
                  color={siteAgreementPdfPath ? 'GREEN_1' : 'primary'}
                  sx={{ color: siteAgreementPdfPath ? WHITE : '' }}
                >
                  {siteAgreementPdfPath && siteAgreementData?.signDate
                    ? `Signed ${formatDate(siteAgreementData?.signDate)}`
                    : 'Sign Site Agreement'}
                </Button>
              </Styled.StepWrapper>
              <Styled.StepWrapper>
                <Styled.StepNumber variant="EC_TYPE_3XL">2.</Styled.StepNumber>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={!canUserSignExternshipAgreement} 
                  onClick={(): void =>
                    siteAgreementData?.agreementNeeded
                      ? setOpenSiteAgreementNotSignedModal(true)
                      : signedExternshipAgreement
                      ? openEaPdf()
                      : setOpenExternshipAgreementModal(true)
                  }
                  color={signedExternshipAgreement ? 'GREEN_1' : 'primary'}
                  sx={{ color: signedExternshipAgreement ? WHITE : '' }}
                >
                  {signedExternshipAgreement && managerEaSignDate
                    ? `Signed ${formatDate(
                        new Date(managerEaSignDate).toISOString()
                      )}`
                    : 'Sign Externship Agreement'}
                </Button>
              </Styled.StepWrapper>
            </Styled.AgreementCtaSection>
          )}
          {/* right cta section */}
          {displayRejectBtn && (
            <Styled.CtaButton
              variant="outlined"
              onClick={(): void => setConfirmRejectModalOpen(true)}
            >
              Reject Externship
            </Styled.CtaButton>
          )}
          {displayProxyAgreement && (
            <Styled.CtaButton
              variant="contained"
              onClick={(): void => setOpenProxyAgreementModal(true)}
            >
              Sign Proxy Agreement
            </Styled.CtaButton>
          )}
          {displayReviewTimesheets && (
            <Styled.CtaButton
              component={RouterLink}
              to={`/externship/${externshipId}/timesheets`}
              variant="contained"
              disabled={!currentUserCanApproveTimesheets}
              className="ga-cta"
            >
              Review Timesheets
            </Styled.CtaButton>
          )}
        </Styled.CtaSection>
      </Styled.Root>
      <EcDialog
        title="Are you sure you want to reject this externship?"
        content="If you reject this externship it will be permanently removed."
        open={confirmRejectModalOpen}
        handleClose={(): void => setConfirmRejectModalOpen(false)}
        handleConfirm={handleRejectClick}
        confirmActionText="Reject Externship"
        denyActionText="Close"
      />
      <SiteAgreementNotSignedModal
        open={openSiteAgreementNotSignedModal}
        handleClose={(): void => setOpenSiteAgreementNotSignedModal(false)}
      />
      <ExternshipAgreement
        externshipId={externshipId}
        open={openExternshipAgreementModal}
        agreementTitle="Externship Agreement"
        handleClose={(): void => setOpenExternshipAgreementModal(false)}
        updateExternship={handleUpdateExternship}
      />
      <ProxyAgreement
        employer={employer}
        externship={externship}
        agreementTitle="Supervisor/Proxy Agreement"
        open={openProxyAgreementModal}
        handleClose={(): void => {
          setOpenProxyAgreementModal(false);
        }}
        updateExternship={handleUpdateExternship}
      />
    </>
  );
};

export default ExternshipsTableRowRedesign;
