import * as React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import {
  ExternshipStatus,
  Timesheet,
  TimesheetPerformanceItem,
  TimesheetStatus,
  TimesheetsWithExternship
} from '@api/models/externshipApi.models';
import { getFilePath } from '@common/helpers/getFilePath';
import { getTwoLineAddress } from '@common/helpers/addressHelpers/getTwoLineAddress';
import NoDataMessage from '@components/NoDataMessage';
import PdfLink from '@components/PdfLink';
import { styled } from '@mui/system';
import TimesheetAccetStatement from '@pages/Shared/TimesheetPage/TimesheetAccetStatement';
import TimesheetAccordion from '@pages/Shared/TimesheetPage/TimesheetAccordion';

const Styled = {
  OverviewSection: styled(Box)(({ theme }) => ({
    display: 'flex',
    columnGap: '48px',
    rowGap: '48px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  })),
  ExternshipInfo: styled(Box)({
    flex: 3
  }),
  CandidateOrCompanyName: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main
  })),
  ExternshipDetailsWrapper: styled(Box)({
    display: 'flex',
    columnGap: '75px',
    rowGap: '32px',
    margin: '16px 0px 0px',
    flexWrap: 'wrap'
  }),
  ExternshipDetail: styled(Box)({
    minWidth: '150px'
  }),
  AccordionWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'showIcon'
  })<{ showIcon: boolean }>(({ showIcon, theme }) => ({
    position: 'relative',
    '& i': {
      position: 'absolute',
      color: theme.palette.primary.main,
      fontSize: '24px',
      left: '-25px',
      top: '32px',
      ...(!showIcon && { display: 'none' })
    }
  })),
  Divider: styled(Divider)(({ theme }) => ({
    margin: '16px 0',
    backgroundColor: theme.palette.GRAY_2.main
  }))
};

interface Props {
  timesheetsWithExternship: TimesheetsWithExternship;
  isApprover: boolean;
  timesheetPerformanceItems: TimesheetPerformanceItem[];
  updateTimesheets: (timesheet: Timesheet) => void;
}

const TimesheetSiteInfo: React.FC<Props> = ({
  timesheetsWithExternship,
  isApprover,
  timesheetPerformanceItems,
  updateTimesheets
}) => {
  const { externship, timesheets } = timesheetsWithExternship;

  const approvers = React.useMemo(() => {
    const approverNames: string[] = externship?.approvers.map(approver => `${approver.firstName} ${approver.lastName}`) ?? [];

    return approverNames.join(', ');
  }, [externship]);

  const location =
    externship.company?.locations[0] ?? externship.proposedCompany.location;
  const externshipComplete = externship.status === ExternshipStatus.COMPLETED;
  const pdf = getFilePath(externship.externshipAgreement?.file?.fullName);

  const handleUpdateTimesheets = React.useCallback(
    (timesheet: Timesheet) => {
      updateTimesheets(timesheet);
    },
    [updateTimesheets]
  );

  return (
    <>
      <Styled.OverviewSection>
        <Styled.ExternshipInfo>
          <Styled.CandidateOrCompanyName
            variant="EC_TYPE_3XL"
            data-testid="timesheet-candidate-or-company-name"
          >
            {externship.company.name}
          </Styled.CandidateOrCompanyName>
          <Styled.ExternshipDetailsWrapper>
            <Styled.ExternshipDetail>
              {getTwoLineAddress(location)}
            </Styled.ExternshipDetail>
            <Styled.ExternshipDetail>
              <Typography variant="EC_TYPE_XL">Approver&#40;s&#41;</Typography>
              <Typography variant="EC_TYPE_BASE">{approvers}</Typography>
            </Styled.ExternshipDetail>
            {externship.externshipAgreement?.file && (
              <Box ml="auto">
                <PdfLink
                  pdfPath={pdf}
                  buttonText="Download Externship Agreement"
                />
              </Box>
            )}
          </Styled.ExternshipDetailsWrapper>
        </Styled.ExternshipInfo>
      </Styled.OverviewSection>
      <Styled.Divider />
      <Box mb={5}>
        <TimesheetAccetStatement />
      </Box>
      {!timesheets.length && <NoDataMessage title="No Timesheets Available" />}
      {timesheets?.map((t, idx) => (
        <Styled.AccordionWrapper
          key={t.id + idx}
          showIcon={
            isApprover &&
            t.status === TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL
          }
        >
          <i className="ri-arrow-right-circle-fill" />
          <TimesheetAccordion
            key={t.id + idx}
            timesheet={t}
            updateTimesheets={handleUpdateTimesheets}
            isApproverViewing={isApprover}
            timesheetPerformanceItems={timesheetPerformanceItems}
            externshipComplete={externshipComplete}
          />
        </Styled.AccordionWrapper>
      ))}
    </>
  );
};

export default TimesheetSiteInfo;
