import * as React from 'react';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import EcGridTableToolbar from '@components/DataGrid/EcGridTableToolbar';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { InviteApi } from '@api/Invite.api';
import InviteUserBox from '../CompanySettings/InviteUserBox';
import { useCompanySettingsContext } from '@common/context/companySettingsContext';
import { UserInviteEntity } from '@api/models/inviteApi.models';

interface InvitesTabContentProps {
  companyId: number;
  refreshSeatsData?: () => void;
};

const InvitesTabContent: React.FC<InvitesTabContentProps> = ({
  companyId,
  refreshSeatsData
}) => {
  const [sentInvites, setSentInvites] = React.useState<UserInviteEntity[]>([]);
  const {
    openToast,
  } = useCompanySettingsContext();

  React.useEffect(() => {
    fetchSentInvites();
  }, []);

  const fetchSentInvites = async (): Promise<void> => {
    try {
      const response = await InviteApi.getSentInvitesByCompanyId(companyId);

      setSentInvites(response.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> InviteApi.getSentInvitesByCompanyId()',
        error
      );
    }
  };

  const inviteColumns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      filterOperators: allowedGridFilterOperators,
    },
    {
      field: 'sentAt',
      headerName: 'Sent Date',
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      valueFormatter: (params: { value: unknown }): string =>
        typeof params.value === 'string' ? formatDate(params.value) : 'Not sent',
    },
    {
      field: 'expiresAt',
      headerName: 'Expiration',
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      valueFormatter: (params: { value: unknown }): string =>
        typeof params.value === 'string' ? formatDate(params.value) : 'No expiration',
    },
    {
      field: 'employerRole',
      headerName: 'Role',
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      valueGetter: (params: { row: UserInviteEntity }): string => 
        params.row.companyToUserInvites && params.row.companyToUserInvites[0]?.employerRole || '',
    },
  ];
  return (
    <>
      <InviteUserBox
        companyId={companyId}
        refreshSentInvites={fetchSentInvites}
        openToast={openToast}
        refreshSeatsData={refreshSeatsData}
      />
      <EcDataGrid
        autoHeight={true}
        rowSelection
        columns={inviteColumns}
        rows={sentInvites}
        slots={{
          toolbar: EcGridTableToolbar
        }}
      />
    </>
  );
};

export default InvitesTabContent;
