import {
  Seat,
  SetLoginSettings,
  UserLoginSettings
} from '@api/models/settingsApi.models';
import { CandidateApi } from '@api/Candidate.api';
import { CompanyApi } from '@api/Company.api';
import { EmployerProfile } from '@api/models/employerApi.models';
import { InviteApi } from '@api/Invite.api';
import { JoinRequest } from '@api/models/companyApi.models';
import { useState } from 'react';
import { UserInviteEntity } from '@api/models/inviteApi.models';

interface FetchUserSettings {
  loginSettings?: UserLoginSettings;
  sentInvites: UserInviteEntity[];
  joinRequests: JoinRequest[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  fetchCandidateSettings: (userId: string) => Promise<void>;
  fetchCompanyLoginSettings: (userId: string) => Promise<void>;
  fetchSentInvites: (companyId: number) => Promise<void>;
  fetchJoinRequests: (companyId: number) => Promise<void>;
  fetchCompanySettingsRequestsAndInvites: (companyId: number, companyProfileName?: string | null) => Promise<void>;
  setLoginSettings: SetLoginSettings;
  setJoinRequests: React.Dispatch<React.SetStateAction<JoinRequest[]>>;
}

export const convertEmployersToSeats = (
  employers: EmployerProfile[],
  companyId: number
): Seat[] => {
  const seats = employers.map((employer) => {
    const currentCompanyLinks = employer?.companyToEmployers?.filter(companyLink => companyLink.company.id === companyId);
    const fullName = `${employer?.user?.firstName} ${employer?.user?.lastName}`;
    return {
      employerId: employer?.id,
      email: employer?.user?.email,
      name: fullName,
      keycloakUserRoles: employer.user?.userToRoles?.map(link => link.role),
      employerRoles: currentCompanyLinks?.map(link => link.employerRole),
      isSelf: false, // TODO: determine if this employer is the currently logged in user
      e_source_group: employer.e_source_group
    } as Seat;
  });
  return seats;
};

export const fetchCompanySettings = async (
  companyId: number,
  companyProfileName?: string | null,
  setLoading?: (loading: boolean) => void,
): Promise<void> => {
  try {
    setLoading && setLoading(true);

    const res = await CompanyApi.getEmployersByCompanyId(companyId);
    const seats = convertEmployersToSeats(res.data, companyId);
    console.debug('useFetchSettings: fetchCompanySettings: employers and seats:', res.data, seats);
  } catch (error: any) {
    console.error('Error for useFetchSettings.fetchCompanySettings -> CompanyApi.getEmployersByCompanyId()', error);
  } finally {
    setLoading && setLoading(false);
  }
};

export const useFetchSettings = (): FetchUserSettings => {
  const [loginSettings, setLoginSettings] = useState<UserLoginSettings>();
  const [sentInvites, setSentInvites] = useState<UserInviteEntity[]>([]);
  const [joinRequests, setJoinRequests] = useState<JoinRequest[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCandidateSettings = async (userId: string): Promise<void> => {
    try {
      setLoading(true);
      const res = await CandidateApi.getByKeycloakId(userId);
      setLoginSettings((prev) => ({
        ...prev,
        email: res.data.email,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        phone: res.data.phone,
        jobTitle: ''
      }));
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> CandidateApi.getByKeycloakId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyLoginSettings = async (userId: string): Promise<void> => {
    try {
      setLoading(true);
      const res = await CompanyApi.getEmployerByKeycloakId(userId);
      setLoginSettings((prev) => ({
        ...prev,
        email: res.data.email,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        phone: res.data.phone,
        jobTitle: res.data.jobTitle
      }));
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> CompanyApi.getByKeycloakId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSentInvites = async (companyId: number): Promise<void> => {
    try {
      setLoading(true);

      // TODO: new invite infrastructure
      const res = await InviteApi.getSentInvitesByCompanyId(companyId);

      setSentInvites(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> InviteApi.getSentInvitesByCompanyId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchJoinRequests = async (companyId: number): Promise<void> => {
    try {
      setLoading(true);

      // TODO: update with new infrastructure
      const res = await CompanyApi.getPendingRequests(companyId);

      setJoinRequests(res);
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> CompanyApi.getPendingRequests()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanySettingsRequestsAndInvites = async (
    companyId: number,
  ): Promise<void> => {
    Promise.all([
      fetchSentInvites(companyId),
      fetchJoinRequests(companyId),
    ]);
  };

  return {
    loginSettings,
    sentInvites,
    joinRequests,
    loading,
    setLoading,
    fetchCandidateSettings,
    fetchCompanyLoginSettings,
    fetchSentInvites,
    fetchJoinRequests,
    fetchCompanySettingsRequestsAndInvites,
    setLoginSettings,
    setJoinRequests
  };
};
