/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import DashboardTableLayout, {
  DashboardTableLayoutProps
} from '@components/TableComponents/DashboardTableLayout';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ContactUsSection from './ContactUsSection';
import EmployerDashExternshipsSection from '@pages/Employer/EmployerDashboard/EmployerDashExternshipsSection';
import ExternshipWelcomeModal from './ExternshipWelcomeModal';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { useFetchSiteAgreementData } from '@common/fetches/useFetchSiteAgreementData';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { EmployerApi } from '@api/Employer.api';
import { EmployerProfile } from '@api/models/employerApi.models';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import EmployerDashboardCompanyCard from './EmployerDashboardCompanyCard';

const Styled = {
  ExternshipBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '80px'
  }),
  PageHeaderTitle: styled(Typography)(({ theme }) => ({
    marginRight: '8px',
    marginBottom: '6px',
    fontSize: '36px',
    lineHeight: '44px',
    color: theme.palette.GOLD_1.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })),
};

const EmployerDashboard: React.FC = () => {
  const { isRecruiter, isEmployer } = useKeycloakContext();
  const [employerId] = useStore<number>(UseStoreKeys.EMPLOYER_ID);
  const [employer, setEmployer] = React.useState<EmployerProfile | null>(null);
  const [externships, setExternships] = React.useState<ExternshipEntity[]>([]);

  // We should retrieve site agreement info if it should be displayed
  // should not matter if user is admin or recruiter
  const { siteAgreementData } = useFetchSiteAgreementData(
    employerId,
    isEmployer || isRecruiter
  );

  React.useEffect(() => {
    const fetchEmployer = async (employerId): Promise<void> => {
      const fetchedEmployer = await EmployerApi.getEmployerById(employerId);
      setEmployer(fetchedEmployer);
    };
    const fetchExternships = async (employerId): Promise<void> => {
      const fetchedExternships = await EmployerApi.getExternshipsForEmployer(employerId);
      setExternships(fetchedExternships);
    };

    if (employerId) {
      fetchEmployer(employerId);
      fetchExternships(employerId);
    }
  }, [employerId]);

  const tableLayoutProps: DashboardTableLayoutProps = {
    pageTitle: 'Employer Dashboard' ,
    logo: undefined,
  };
  
  return (
    <RootPageLayout bgImgVariant={1}>
          <DashboardTableLayout {...tableLayoutProps}>
            {!!employer && !!externships?.length &&  (
              <Styled.ExternshipBox>
                <EmployerDashExternshipsSection
                  employer={employer}
                  externships={externships}
                  siteAgreementData={siteAgreementData}
                  setExternships={setExternships}
                />
              </Styled.ExternshipBox>
            )}
            {!!employer?.companyToEmployers && employer.companyToEmployers.map((companyToEmployers) => (
              <EmployerDashboardCompanyCard 
                key={companyToEmployers.company.id}
                company={companyToEmployers.company} 
                employerRole={companyToEmployers.employerRole}
              />
            ))}

            <ExternshipWelcomeModal 
              employer={employer}
            />
            {<ContactUsSection />}
          </DashboardTableLayout>
    </RootPageLayout>
  );
};

export default EmployerDashboard;
