import { createCustomContext } from '@common/context/customContext';
import { IKeycloakUser } from '@api/models/userApi.models';

export interface KeycloakContextType {
  isAdmin: boolean;
  isCandidate: boolean;
  isEcAdmin: boolean;
  isEcCareerService: boolean;
  isChefInstructor: boolean;
  isEmployer: boolean;
  isExternshipTimesheetApprover: boolean;
  isRecruiter: boolean;
  isGlobalSolutions: boolean;
  isGlrcUser: boolean;
  isGlrcRecruiter: boolean;
  isPendingUser: boolean;
  keycloakUser: IKeycloakUser;
  isAuthenticated?: boolean;
  hasValidRole?: boolean;
  hasValidRoleOtherThanExternshipTimesheetApprover?: boolean;
}

/**
 * KeycloakContextProvider is used in KeycloakProviderWrapper.tsx to provide
 * the app with KeycloakContextType data.
 *
 * useKeycloakContext is used throughout the front end of app to gain access to
 * KeycloakContextType data.
 */
export const [useKeycloakContext, KeycloakContextProvider] =
  createCustomContext<KeycloakContextType>();
