import {
  InviteCandidate,
  UserInviteEntity,
  SendInviteRequest,
} from '@api/models/inviteApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';

export class InviteApi {
  static async sendInvite(
    invite: SendInviteRequest
  ): Promise<ApiResponse<UserInviteEntity>> {
    try {
      const response = await api.post<ApiResponse<UserInviteEntity>>(
        `${apiConfig.inviteUrl}`,
        invite
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for InviteApi.sendInvite(): ${error}`);
      throw error;
    }
  }

  static async getInviteInfo(
    inviteCode: string
  ): Promise<ApiResponse<UserInviteEntity>> {
    try {
      const endpoint: string = `${apiConfig.inviteUrl}/${inviteCode}`;
      const response = await api.get<ApiResponse<UserInviteEntity>>(endpoint);
      return response.data;

    } catch (error: any) {
      console.error(`Error for InviteApi.getInviteInfo(): ${error}`);
      throw error;
    }
  }

  static async getSentInvitesByCompanyId(
    companyId: number
  ): Promise<ApiResponse<UserInviteEntity[]>> {
    try {
      const response = await api.get<ApiResponse<UserInviteEntity[]>>(
        `${apiConfig.inviteUrl}/${companyId}/sent-invites`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for InviteApi.getSentInvitesByCompanyId(): ${error}`
      );
      throw error;
    }
  }

  static async inviteCandidate(
    data: InviteCandidate
  ): Promise<ApiResponse<InviteCandidate>> {
    try {
      const response = await api.post<ApiResponse<InviteCandidate>>(
        `${apiConfig.inviteUrl}/candidate`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for InviteApi.inviteCandidate(): ${error}`);
      throw error;
    }
  }
}
