import { ExternshipEntity } from '@api/models/externshipApi.models';
import { SiteCardApproverDetails } from '@pages/CareerServices/StudentPage/StudentSiteInfo/ApproverDetails';

export const getSupervisorsForCsStudentView = (
  externship: ExternshipEntity
): SiteCardApproverDetails[] => {
  const { supervisors } = externship;

  const proposedSupervisors = externship.proposedCompany?.supervisors;

  let proposedSupervisorArray: SiteCardApproverDetails[] = [];

  if (!proposedSupervisors?.length && !supervisors?.length) {
    return [] as SiteCardApproverDetails[];
  } else {
    proposedSupervisorArray =
      proposedSupervisors?.map((s) => ({
        firstName: s.firstName,
        lastName: s.lastName,
        phoneNumber: s.phoneNumber,
        email: s.email,
        jobTitle: s.jobTitle,
        externshipRole: 'supervisor',
        approverError: s.approverError,
        approverHasCreatedAccount: false
      })) || [];

    if (!supervisors?.length) {
      return proposedSupervisorArray;
    } else {
      const supervisorArray = supervisors
        .filter((s) => !s.removeDate)
        .map((s) => ({
          firstName: s.employer.firstName,
          lastName: s.employer.lastName,
          phoneNumber: s.employer.phone,
          email: s.employer.email,
          jobTitle: s.employer.jobTitle,
          externshipRole: 'supervisor',
          proxyAgreement: s.proxyAgreement,
          active: s.active,
          approverHasCreatedAccount: true,
          id: s.id.toString()
        }));

      // check for proposedSupervisors that have not created an account
      const newSupervisors = proposedSupervisorArray.filter(
        (proposedSupervisor) =>
          !supervisorArray.find(
            (supervisor) =>
              supervisor.email.toLowerCase() ===
              proposedSupervisor.email.toLowerCase()
          )
      );

      return [...supervisorArray, ...newSupervisors] as SiteCardApproverDetails[];
    }
  }
};
