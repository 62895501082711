import * as React from 'react';
import {
  ExternshipEntity,
  SiteAgreementData
} from '@api/models/externshipApi.models';
import Box from '@mui/material/Box';
import { ExternshipApi } from '@api/Externships.api';
import ExternshipsTableRowRedesign from './ExternshipsTableRowRedesign';
import SiteAgreement from '@components/ExternshipAgreements/SiteAgreement';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useTableSortContext } from '@common/context/tableSortContext';
import { EmployerProfile } from '@api/models/employerApi.models';

const Styled = {
  TableRoot: styled(Box)({
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_1.main}`,
    borderBottom: 'none',
    borderRadius: '0 0 4px 4px',
    overflow: 'hidden'
  }),
  RowBox: styled(Box)({
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    ':hover': {
      backgroundColor: theme.palette.gold1.main
    }
  })
};

interface Props {
  employer: EmployerProfile;
  externships: ExternshipEntity[];
  siteAgreementData?: SiteAgreementData[];
  setExternships: React.Dispatch<
    React.SetStateAction<ExternshipEntity[]> // eslint-disable-line @typescript-eslint/indent
  >;
}

const ExternshipsTable: React.FC<Props> = ({
  employer,
  externships,
  siteAgreementData,
  setExternships
}) => {
  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();
  const [siteAgreementModalOpen, setSiteAgreementModalOpen] = React.useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<number | null>(null);
  const [_siteAgreementData, setSiteAgreementData] = React.useState<SiteAgreementData[]>();

  React.useEffect(() => {
    if (siteAgreementData) {
      setSiteAgreementData(siteAgreementData);
    }
  }, [siteAgreementData]);

  const handleOpenSiteAgreementModal = (companyId: number): void => {
    setSelectedCompanyId(companyId);
    setSiteAgreementModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setSiteAgreementModalOpen(false);
    setSelectedCompanyId(null);
  };

  const handleRejectExternship = async (
    externshipId: number
  ): Promise<void> => {
    try {
      await ExternshipApi.decline(externshipId);

      const updatedExternships = externships.filter(
        (ex) => ex.id !== externshipId
      );

      setExternships(updatedExternships);
    } catch (error: any) {
      console.error(
        `Error for ExternshipsTable -> ExternshipApi.decline(): ${error}`
      );
    }
  };

  return (
    <>
      <Styled.TableRoot data-testid="externships-table-root">
        {sortTableData(externships, sortOrder, sortBy as keyof ExternshipEntity)
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((ext) => {
            const siteAgreement = _siteAgreementData?.find(
              agreement => agreement.companyId === ext.company?.id
            );
            return (
              <Styled.RowBox key={ext.id}>
                <ExternshipsTableRowRedesign
                  {...ext}
                  employer={employer}
                  handleRejectExternship={handleRejectExternship}
                  handleOpenSiteAgreementModal={() => handleOpenSiteAgreementModal(ext.company?.id)}
                  siteAgreementData={siteAgreement}
                />
              </Styled.RowBox>
            );
          })}
      </Styled.TableRoot>
      {siteAgreementModalOpen && selectedCompanyId && _siteAgreementData && (
        <SiteAgreement
          siteAgreementFormData={_siteAgreementData?.find(sa => sa.companyId === selectedCompanyId)}
          employerId={employer.id}
          agreementTitle="Site Agreement"
          open={siteAgreementModalOpen}
          handleClose={handleCloseModal}
          setSiteAgreementData={(updatedData) => {
            setSiteAgreementData(prev => {
              if (!prev) return prev;
              return prev.map(sa => 
                sa.companyId === selectedCompanyId 
                  ? { ...sa, ...updatedData }
                  : sa
              );
            });
          }}
        />
      )}
    </>
  );
};

export default ExternshipsTable;
