import * as React from 'react';
import {
  activeEmployerNav,
  candidateNav,
  careerServicesNav,
  chefInstructorNav,
  escoffierAdminNav,
  escoffierApproverNav,
  inactiveEmployerNav,
  limboSsoNav,
  partnerNav,
  pendingUserNav,
  unauthenticatedNav
} from '@components/Layout/Header/NavigationLists';
import {
  candidateSettingsNav,
  employerSettingsNav,
  escoffierAdminSettingsNav,
  partnerSettingsNav,
  recruiterSettingsNav
} from '@pages/Shared/SettingsPage/SettingsNavLinks';
import { store, useStore } from 'react-context-hook';
import { useMediaQuery, useTheme } from '@mui/material';
import { contentManagementNavLinks } from '@pages/EcAdmin/ContentManagementPage/ContentManagementPage';
import DesktopNav from '@components/Layout/Header/DesktopNav';
import { loginWithStorageRedirect } from '@common/helpers/login';
import MobileNav from '@components/Layout/Header/MobileNav';
import ResponsiveLayout from '../ResponsiveLayout';
import { useDeleteStoreValue } from 'react-context-hook';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCompanyTheme } from '@common/fetches/useFetchCompanyTheme';
import { useKeycloak } from '@react-keycloak/web';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';

const Header: React.FC = () => {
  const { sendToGtm } = useTagManager();
  const featureFlags = useFeatureFlags();

  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const { companyId } = useParamsOrStoreCompanyId();
  const { companyTheme } = useFetchCompanyTheme(companyId);

  const theme = useTheme();
  const isAtOrBelowHeaderBreakpoint = useMediaQuery(
    theme.breakpoints.down('headerMobile')
  );

  const isDev = process.env.REACT_APP_FEATURE_FLAG_ENV === 'dev';

  const { keycloak } = useKeycloak();
  const {
    isAuthenticated,
    isCandidate,
    isEcAdmin,
    isEcCareerService,
    isExternshipTimesheetApprover,
    isEmployer,
    isRecruiter,
    isPartner,
    isGlrcUser,
    isPendingUser,
    isChefInstructor,
    keycloakUser,
    hasValidRoleOtherThanExternshipTimesheetApprover
  } = useKeycloakContext();

  // User is in limbo state if they are an authenticated user with no roles
  const hasNoRoles =
    !!keycloakUser && (!keycloakUser.roles || !keycloakUser.roles.length);

  const deleteCompanyId = useDeleteStoreValue(UseStoreKeys.COMPANY_ID);
  const deleteCandidateId = useDeleteStoreValue(UseStoreKeys.CANDIDATE_ID);
  const deleteRole = useDeleteStoreValue(UseStoreKeys.ROLE);

  const [isEnabledCompany] = useStore(UseStoreKeys.COMPANY_ENABLED);

  let currNav = unauthenticatedNav;
  let settingsLinks: any;
  let contentManagementLinks: any;

  const [isParentCompany] = useStore<boolean>(UseStoreKeys.IS_PARENT_COMPANY);

  if (isPendingUser) {
    currNav = pendingUserNav;
  } else if (isEmployer) {
    currNav = isEnabledCompany
      ? activeEmployerNav(isParentCompany)
      : inactiveEmployerNav;
    settingsLinks = employerSettingsNav(featureFlags, !!companyTheme);
  } else if (isRecruiter) {
    currNav = isEnabledCompany
      ? activeEmployerNav(isParentCompany)
      : inactiveEmployerNav;
    settingsLinks = recruiterSettingsNav;
  } else if (isCandidate) {
    currNav = candidateNav(candidateId);
    settingsLinks = candidateSettingsNav;
  } else if (isEcAdmin) {
    currNav = escoffierAdminNav();
    settingsLinks = escoffierAdminSettingsNav;
    contentManagementLinks = featureFlags.CONTENT_MANAGEMENT
      ? contentManagementNavLinks()
      : undefined;
  } else if (isPartner || isGlrcUser) {
    currNav = partnerNav();
    settingsLinks = partnerSettingsNav;
  } else if (isEcCareerService) {
    currNav = careerServicesNav(isDev);
  } else if (isChefInstructor) {
    currNav = chefInstructorNav;
  } else if (isExternshipTimesheetApprover) {
    currNav = escoffierApproverNav;
  } else if (hasNoRoles) {
    currNav = limboSsoNav;
  }

  // Check if every authenticated user has Account Settings, if not add it
  if (
    isAuthenticated &&
    currNav.avatarLinks.every(
      (nav) => !Object.values(nav).includes('Account Settings')
    )
  ) {
    currNav.avatarLinks?.push({
      label: 'Account Settings',
      path: '/settings/account',
      remixicon: 'ri-settings-4-fill',
      hideInMobile: true
    });
  }

  // For a user who is an Approver but also has any other valid role
  // Trying to add Externships link after a Dashboard link.
  // If the Dashboard link is not present in the NavBar's primary links array, then
  // adding Externships link to the end of the links array (end if the menu)
  if (hasValidRoleOtherThanExternshipTimesheetApprover && isExternshipTimesheetApprover) {
    const dashboardLinkIndex = currNav.primaryLinks.findIndex((item) => {
      return item.label.toLowerCase() === 'dashboard';
    });

    if (dashboardLinkIndex > -1) {
      currNav.primaryLinks.splice(dashboardLinkIndex + 1, 0, ...escoffierApproverNav.primaryLinks);
    } else {
      currNav.primaryLinks.push(...escoffierApproverNav.primaryLinks);

    }
  }

  React.useEffect(() => {
    if (isAtOrBelowHeaderBreakpoint && isAuthenticated) {
      // Add settings to currNav
      currNav.settingsLinks = settingsLinks?.map((item) => ({
        ...item,
        path: `/settings${item.path}`
      }));

      currNav.contentManagementLinks = contentManagementLinks?.map((item) => ({
        ...item,
        path: `/admin/content-management${item.path}`
      }));
    }
  }, [isAtOrBelowHeaderBreakpoint, isAuthenticated]);

  const logout = React.useCallback(() => {
    sendToGtm('navigation', {
      navigate_to: 'sign-out',
      component: 'header',
      link_text: 'Sign Out'
    });

    // NOTE Per lib documentation, these empty strings are not required,
    // but TS is complaining without them.
    deleteCompanyId('');
    deleteCandidateId('');
    deleteRole('');
    store.reset({});
    keycloak.logout({
      redirectUri: `${window.location.origin}/logout`
    });
  }, [keycloak]);

  const login = React.useCallback(() => {
    sendToGtm('navigation', {
      navigate_to: 'sign-in',
      component: 'header',
      link_text: 'Sign In'
    });
    loginWithStorageRedirect(keycloak.login);
  }, [keycloak.login]);

  return (
    <ResponsiveLayout
      useMobileContainer={false}
      customBreakpoint={1019}
      desktopComponent={
        <DesktopNav
          nav={currNav}
          login={login}
          logout={logout}
          isAuthenticated={!!isAuthenticated}
        />
      }
      mobileComponent={
        <MobileNav
          nav={currNav}
          login={login}
          logout={logout}
          isAuthenticated={!!isAuthenticated}
        />
      }
    />
  );
};

export default Header;
