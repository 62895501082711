import { useEffect, useState } from 'react';
import { ExternshipApi } from '@api/Externships.api';
import { SiteAgreementData } from '@api/models/externshipApi.models';

interface FetchSiteAgreementData {
  siteAgreementData?: SiteAgreementData[];
  loading: boolean;
}

export const useFetchSiteAgreementData = (
  employerId: number,
  isEmployer: boolean
): FetchSiteAgreementData => {
  const [siteAgreementData, setSiteAgreementData] = useState<SiteAgreementData[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);

        const res = await ExternshipApi.getSiteAgreementData(employerId);
        setSiteAgreementData(res.data);
      } catch (error: any) {
        console.error(
          'Error for useFetchSiteAgreementData -> ExternshipApi.getSiteAgreementData',
          error
        );
      } finally {
        setLoading(false);
      }
    };

    if (isEmployer) {
      fetchData();
    }
  }, []);

  return { siteAgreementData, loading };
};
